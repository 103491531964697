
// EU cookie bar
// this component using https://github.com/js-cookie/js-cookie

// version 1.1.0

var cookieBar = {

    defaults: {
        idElem: 'cookie-bar',
        classElem: 'cookie-bar',
        cookieKey: 'cookie-bar-key',
        infoText: 'Tento web používá k&nbsp;poskytování služeb a analýze návštěvnosti soubory cookie. Používáním tohoto webu s&nbsp;tím souhlasíte -&nbsp;<a tabindex="0" href="https://www.google.com/policies/technologies/cookies/" target="_blank">další&nbsp;informace</a>',
        buttonClasses: 'cookie-bar__btn',
        buttonText: 'Souhlasím',
        eumemberstates: [
            "BE",
            "BG",
            "CZ",
            "DK",
            "EE",
            "FI",
            "FR",
            "HR",
            "IE",
            "IT",
            "CY",
            "LV",
            "LT",
            "LU",
            "HU",
            "MT",
            "DE",
            "NL",
            "PL",
            "PT",
            "AT",
            "RO",
            "GR",
            "SK",
            "SI",
            "GB",
            "ES",
            "SE"
        ],
    },

    settings: null,

    init: function (settings) {

        // extend default settings by user
        cookieBar.settings = $.extend( {}, cookieBar.defaults, settings );
		if (!cookieBar.isCookieExist()) {
        // http://ipinfo.io/developers/#jsonp-requests
        // US Test >> //ipinfo.io/8.8.8.8/geo
        $.get("//ipinfo.io/", function(response) {
            if (!cookieBar.isCookieExist() && cookieBar.isClientFromEU(response)) {
                //create element
                cookieBar.createEucookieBar();
            }
        }, "jsonp");
		}

    },

    isClientFromEU: function(response) {

        var isFromEU = false;
        console.log(response.ip, response.country);

        if($.inArray(response.country, cookieBar.defaults.eumemberstates) != -1) {
            isFromEU = true;
        }
        return isFromEU;

    },

    createEucookieBar: function() {

        // cookie bar html
        var html =`<div id="${cookieBar.settings.idElem}" class="${cookieBar.settings.classElem}" role="dialog" aria-label="Cookie lišta" aria-describedby="cookie-bar__text">
                     <p id="cookie-bar__text" class="${cookieBar.settings.classElem}__text">${cookieBar.settings.infoText}</p>
                     <button class="${cookieBar.settings.buttonClasses}" tabindex="0" aria-label="Souhlasím s používáním cookie souborů">${cookieBar.settings.buttonText}</button>
                   </div>`;

        //apend cookieElem to Body element
        $('body').append(html);

        //register on click
        $('#' + cookieBar.settings.idElem).on('click', 'button', function() {
            Cookies.set(cookieBar.settings.cookieKey, 1, { expires: 999 });

            var cookieBarElem = $('#' + cookieBar.settings.idElem).addClass(cookieBar.settings.classElem + '--agreed');
            $(cookieBarElem).one('webkitTransitionEnd mozTransitionEnd MSTransitionEnd otransitionend transitionend', function() {
                $(cookieBarElem).css('display', 'none');
            });
        });

    },

    isCookieExist: function() {
        return Cookies.get(cookieBar.settings.cookieKey);
    }
};

export default cookieBar;
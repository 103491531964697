// this plugin wrap input and add pseudo text
// add class to input that we wanna creat custom placeholder

$('.custom-placeholder').each(function(){
  const $this = $(this);
  const placeholderText = $this.attr('placeholder');

  if ($this.val().length > 0) {
    $this.addClass('hide-placeholder');
  }

  $this.attr('placeholder', '');

  $this.wrap(`<span class="custom-placeholder__wrap"
        style="padding: 0; margin: 0; line-height: 1;">`);


  $this.parent().append(`<span class="custom-placeholder__text">${placeholderText}</span>`);


  $this.on('focus', function(){
      $this.addClass('hide-placeholder');
  });

  $this.on('focusout', function(){
    if($this.val().length === 0) {
      $this.removeClass('hide-placeholder')
    }
  })
});


// mobile menu scripts
;(function ($) {

  "use strict";

  var $header = $("#header"),
      stickyClass = "header--sticky";

  // sticky
  $(window).scroll(function() {
    if( $(this).scrollTop() > 1 ) {
      $header.addClass(stickyClass);
    } else {
      $header.removeClass(stickyClass);
    }
  });

  // toggle nav
  $('#menuButton').on('click', function(e){
    e.preventDefault();
    $(this).closest('.nav__toggle').toggleClass('is-active');
    $header.toggleClass('header--shadow header--open');
  });

})(jQuery);


    var box = {

      defaults: {
        wrapperClass: '.boxes:not(.boxes--random)',
        rowWrapperClass: 'boxes__row',
        itemClass: '.box',
        titleClass: '.box__title',
        cardClass: '.box__card',
        bottomWrapperClass: '.box__bottom-wrapper',
        bottomWrapperActiveClass: 'box__bottom-wrapper--absolute',
      },

      dataAttrMinHeight: 'data-minHeight',
      dataAttrRow: 'data-row',

      settings: null,
      $wrappers: null,

      init: function(options) {

        // set settings
        box.settings = $.extend(box.defaults, options );

        // init elements
        box.$wrappers = $(box.settings.wrapperClass);

        // destroy after init
        box.destroy();

        // set boxes
        box.setBoxes();

      },

      setRow: function(wrapper, itemsOnRow) {
        var $boxes = $(wrapper).find(box.settings.itemClass);
        for(var i = 0; i < $boxes.length; i += itemsOnRow) {
          $boxes.slice(i, i + itemsOnRow).wrapAll('<div class="' + box.settings.rowWrapperClass + '" />');
        }
      },

      storeDefaultHeight: function(elems) {
        for (var i = 0; i < elems.length; i++) {
          $(elems[i]).attr(box.dataAttrMinHeight, $(elems[i]).outerHeight());
        }
      },

      setMaxHeight: function(items) {
        // get max
        var max = 0;
        $(items).each(function() {
          var item = $(this);
          var height = parseFloat(item.attr(box.dataAttrMinHeight));
          max = (max < height ) ? height : max;
        });

        $(items).css('height', Math.ceil(max) + 'px');
      },

      setHeights: function(wrapper, offset, defaults) {

        var $wrapper = wrapper;

        // bottom wrappers
        var $bottomWrappers = $wrapper.find(box.settings.bottomWrapperClass);
        $bottomWrappers.removeClass(box.settings.bottomWrapperActiveClass); // not absolute

        // titles
        var $titles = $wrapper.find(box.settings.itemClass + '>' + box.settings.titleClass);
        $titles.css('height', 'auto'); // remove height first

        // cards
        var $cards = $wrapper.find(box.settings.cardClass);
        $cards.css('height', 'auto'); // remove height first

        // if one box on row --> no calculatiion, heights are auto
        if (!defaults) {

          // defaults
          var $rows = $wrapper.find('.' + box.settings.rowWrapperClass);
          $rows.each(function(index) {

            var $boxes = $(this).find(box.settings.itemClass).not('.box--disable');

            var $rowTitles = $boxes.find('>' + box.settings.titleClass);
            var $rowCards = $boxes.find(box.settings.cardClass);

            // store default heights into data attr
            box.storeDefaultHeight($.merge( $.merge( [], $rowTitles ), $rowCards ));

            // set heights
            box.setMaxHeight($rowTitles);
            box.setMaxHeight($rowCards);

          });

          // position absolute
          $bottomWrappers.addClass(box.settings.bottomWrapperActiveClass);

        }
      },

      destroy: function() {
        box.$wrappers.each(function(index) {

          // get the count of boxes on row
          var $wrap = $(this);
          var $items = $wrap.find(box.settings.itemClass);

          // remove row wrapps first
          $('.' + box.settings.rowWrapperClass).find(box.settings.itemClass).unwrap();

          // set default height
          box.setHeights($wrap, 0, true);

        });
      },

      setBoxes: function() {

        box.$wrappers.each(function(index) {

          // get the count of boxes on row
          var $wrap = $(this);

          // rounded to nearest integer cause IE9
          // http://cruft.io/posts/percentage-calculations-in-ie/#browser-table
          var wrapperWidth = Math.round($wrap.width()) + 1; // wtf corection
          var $items = $wrap.find(box.settings.itemClass);
          var itemWidth = Math.round($($items[0]).outerWidth(true));
          var itemsOnRow = Math.floor(wrapperWidth / itemWidth);

          // remove row wrapps first
          $('.' + box.settings.rowWrapperClass).find(box.settings.itemClass).unwrap();

          // set heights by row
          if (itemsOnRow != 1) {
            setTimeout(function() {
              box.setRow($wrap, itemsOnRow);
              box.setHeights($wrap, itemsOnRow, false);
            }, 150);
          }
          else {
            box.setHeights($wrap, itemsOnRow, true);
          }

        });
      },
    };

export default box;
// price changing in donate step-2

class Slider {
  constructor() {
    this.$donateRange = $('#donateRange');
    this.minValue = this.$donateRange.data('current-value');
  }

  init() {
    const self = this;

    if ( !self.$donateRange.length ) return;

    noUiSlider.create(self.$donateRange[0], {
      start: [ self.minValue ],
      step: 10,
      range: {
        'min': [ self.minValue ],
        'max': [ self.minValue * 5 ]
      },
      format: {
        to: function ( value ) {
          return Math.round(value);
        },
        from: function ( value ) {
          return Math.round(value);
        }
      }
    });

    // update value
    self.$donateRange[0].noUiSlider.on('update', function( values, handle ) {
      $('.donate-value').text(`${values[handle]} Kč`);
    });
  }
}


var slider = new Slider();

slider.init();
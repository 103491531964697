
var $galleryMain = $('.gallery__main');
var $galleryPagination = $('.gallery__paginnation');


var paginationSettings = [
  {
    picturesToShow: 5,
    center: true, //default
  },
  {
    picturesToShow: 4,
    center: true, //default
  },  {
    picturesToShow: 3,
    center: true, //default
  },
];

// Set pagination settings by items count
function setCenterMode(carousel) {
  var items = $(carousel).find('.gallery__image');
  for (var i = 0; i < paginationSettings.length; i++) {
    if(items.length < paginationSettings[i].picturesToShow) {
      paginationSettings[i].center = false;
    }
  }
}
setCenterMode($galleryPagination);



// gallery big
$galleryMain.slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  asNavFor: '.gallery__paginnation',
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: true,
      }
    }
  ]
});

// gallery small - pagination
$galleryPagination.slick({
  infinite: true,
  slidesToShow: paginationSettings[0].picturesToShow,
  slidesToScroll: 1,
  asNavFor: '.gallery__main',
  arrows: true,
  focusOnSelect: true,
  draggable: false,
  swipe: true,
  centerMode: paginationSettings[0].center,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: paginationSettings[0].picturesToShow,
        centerMode: paginationSettings[0].center,
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: paginationSettings[2].picturesToShow,
        centerMode: paginationSettings[2].center,
      }
    }
  ]
});
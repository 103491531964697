
// polyfills for old browsers
import './polyfills/matchMedia.js';
import './polyfills/pointer-events-none.js';
import './polyfills/object.assign.js';

// import libs
import cookieBar from './libs/cookieBar';

// import components
import './components/menu.js';
import './components/gallery.js';
import './components/pie.js';
import box from  './components/box.js';
import'./components/gallery.js';
import'./components/slider.js';
import'./components/placeholder.js';


import './detail.js';

// init
window.ds = {};
window.ds.box = box;

smoothScroll.init();

cookieBar.init({
  // settings
  // infoText: 'Soubory cookies nám pomáhají poskytovat, chránit a zlepšovat naše služby. Prohlížením tohoto webu s jejich používáním souhlasíte.',
  // buttonText: 'Rozumím',
});

// get parameter
// http://www.jquerybyexample.net/2012/06/get-url-parameters-using-jquery.html
var getUrlParameter = function getUrlParameter(sParam) {
  var sPageURL = decodeURIComponent(window.location.search.substring(1)),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

  for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
          return sParameterName[1] === undefined ? true : sParameterName[1];
      }
  }
};

// Show TabPane by URL parameter
function setTabPane() {
  // get parameter
  var tabPaneId = getUrlParameter('tabId');
  if( typeof tabPaneId !== 'undefined' || tabPaneId !== null ) {
    // simulate click on tab navigation
    $('a[href$="' + tabPaneId + '"]').click();
  }
};
$(document).ready(function() {
  setTabPane();
});

const tooltipOpt = {
  contentCloning: true,
  trigger: 'click',
  IEmin: 9,
  side: ['right', 'left','bottom'],
  // minWidth: 400,
  maxWidth: 400,
  functionInit: function(instance, helper){
      var content = $(helper.origin).find('.tooltip__content').detach();
      instance.content(content);
  }
}

$('.tooltip--html:not(.form-tooltip--white)').tooltipster(tooltipOpt);

var tooltipOptWite = Object.assign({
  theme: 'tooltip--white'
}, tooltipOpt);

$('.form-tooltip--white').tooltipster(tooltipOptWite);


// search bar
$('#search').on('focus', '.search__bar', function() {
  $(this).parent().addClass('active');
});

$('#search').on('focusout', '.search__bar', function() {
  $(this).parent().removeClass('active');
});

$('#search').find('.search__bar').hover(

  function() {
    $(this).parent().addClass('active');
  },
  function() {
    if (!$(this).is(':focus')) {
      $(this).parent().removeClass('active');
    }
  }
);

$('.open-popup-link').magnificPopup({
  closeMarkup: '<button class="pop-up__close"></button>',
  type:'inline',
  midClick: true
});

// fix
$(document).on('click', '.pop-up__close', function (e) {
    e.preventDefault();
    $.magnificPopup.close();
});

// new filter
$('.btn--filter').on('click', function(e) {
  $(this).closest('.new-filter').toggleClass('new-filter--active');
});

// paymentGateway tab steps
$('#paymentGateway').on('click', '.btn--next', function(e) {

  var sectionClassName = '.tab-pane__section';

  $(sectionClassName).removeClass('active');
  var $section = $(this).closest(sectionClassName);
  $section.next().addClass('active');

});


// header account switcher
$('#accountSwitcher').dropdown();


// register if window resize
$(window).on('resize', function() {
  box.setBoxes();
});

// event after all google font active
window.DSInitAfterFont = function() {
  box.init();
}




// https://hackernoon.com/a-simple-pie-chart-in-svg-dbdd653b6936

var $pies = $('.pie');

function getCoordinatesForPercent(percent) {
  const x = Math.cos(2 * Math.PI * percent);
  const y = Math.sin(2 * Math.PI * percent);
  return [x, y];
}

var setPieChart = function() {

  // select all pies again
  $pies = $('.pie');

  for (var i = 0; i < $pies.length; i++) {
    var perc = $($pies[i]).attr('data-progress') / 100;
    var cumulativePercent = 0;

    // destructuring assignment sets the two variables at once
    const [startX, startY] = getCoordinatesForPercent(cumulativePercent);

    // each slice starts where the last slice ended, so keep a cumulative percent
    cumulativePercent += perc;

    const [endX, endY] = getCoordinatesForPercent(cumulativePercent);

    // if the slice is more than 50%, take the large arc (the long way around)
    const largeArcFlag = perc > .5 ? 1 : 0;

    // create an array and join it just for code readability
    const pathData = [
      `M ${startX} ${startY}`, // Move
      `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
      `L 0 0`, // Line
    ].join(' ');

    // create a <path> and append it to the <svg> element
    const pathEl = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    pathEl.setAttribute('d', pathData);
    $pies[i].appendChild(pathEl);
  }
}

setPieChart();

$(window).on('ajax-success', function(e) {
  // console.log('ajax success');
  setPieChart();
});